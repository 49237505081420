@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.notFound {
    background-color: #384E65;
    max-height: 85vh;
}
.notFound .text {
    float: left;
    width: 40%;
    position: relative;
}

.notFound h2 {
    font-family: 'Luckiest Guy', cursive;
    color: #fff;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 1.5em;
    font-size: 4em;
}

.notFound p {
    font-family: 'PT Serif', serif;
    color: #fff;
    font-weight: 700;
    position: absolute;
    font-size: 2.3em;
    top: 6em;
    width: 60%;
    margin-left: 20%;
}

.notFound .imageContainer {
    width: 60%;
    height: 87vh;
    float: right;
}

.notFound .imageContainer img {
    width: 100%;
    height: 85vh;
    object-fit: cover;
    overflow: hidden;
}

@media only screen and (max-width: 999px ) {
    .notFound{
        position: relative;
    }
    .notFound .text {
        float: none;
        width: 100%;
        z-index: 100;
        position: absolute;
        top: 0;
    }
    .notFound h2 {
        top: 15px;
    }
    .notFound p {
        top: 4em;
        font-size: 1.5em;
        padding: .5em;
        width: 80%;
        margin-left: 10%;
        background-color: rgba(56, 78, 101, .8);
        border-radius: .5em;
    }
    .notFound .imageContainer {
        float: none;
        width: 100%;
    }

}

@media only screen and (max-width: 600px) {
    .notFound p {
        font-size: 1em;
        top: 6em;
    }
}