@import url('https://fonts.googleapis.com/css2?family=Irish+Grover&display=swap');

.App {
  position: relative;
  min-height: 83vh;
  margin-bottom: 0 !important;
  text-align: center;
}

.navbar-brand svg {
  font-size: 2.5em;
  margin-left: .5em;
  position: relative;
  top: -10px;
}

.brand-text {
  font-family: 'Irish Grover', cursive;
  font-size: 2.5em;
  padding: 0;
}

.nav-link {
  font-size: 1.25em;
}

.bg-purple {
  background-color: #691DA3;
}

section.notFound,
section.about,
section.todos, 
section.categories,
section.login {
  min-height: 85vh;
  background-image: url('./images/todo-bg.jpg');
}

/* section.notFound img,
section.about img,
section.todos img, 
section.categories img,
section.login img {

} */

#editLink:hover, #editLink:focus {
  background-color: #ffc107;
}

#deleteLink:hover, #editLink:focus {
  background-color: #dc3545;
  color: #fff;
}

.footerContainer {
  width: 100%;
}